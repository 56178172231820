.tippy-box[data-theme~="post-tooltip"] {
  --tooltip-body-height: 96px;

  min-width: 180px;
  max-width: 400px !important;

  .tippy-content {
    padding: 0;

    > * {
      padding: 4px 8px;
    }

    .post-tooltip-body {
      max-height: var(--tooltip-body-height);

      &.has-preview {
        max-height: calc(2 * var(--tooltip-body-height));
      }
    }

    div.post-tooltip-header {
      background-color: var(--post-tooltip-header-background-color);
      font-size: 10px;

      .post-tooltip-score .post-votes {
        gap: 2px;
      }

      a.user {
        min-width: 0;
        max-width: 11em;
      }
    }
  }
}
