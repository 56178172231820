div#c-prompt-protector > div#a-index {
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;

  h1 {
    text-align: center;
  }

  span.tn {
    font-style: italic;
  }

  canvas {
    display: none;
  }

  #input {
    margin: 1em auto;
    display: block;
    background-color: #2c2d3f;
    border-radius: 0.5rem;
    max-width: 28rem;
    width: 100%;
    transition: 0.3s;
    position: relative;

    input[type="file"] {
      opacity: 0;
      z-index: 1;
      height: 100%;
      max-width: 28rem;
      width: 100%;
      position: relative;
      cursor: pointer;
    }

    label {
      background-color: #3f4058;
      border-radius: 0.5rem;
      height: 120px;
      max-width: 28rem;
      width: 100%;
      transition: 0.3s;
      border: 2px solid #3f4058;
      position: relative;
      display: flex;
      box-sizing: border-box;

      &:hover {
        border-color: #9192a7;
        outline: none;
      }

      div {
        z-index: 0;
        text-align: center;
        max-width: 28rem;
        position: absolute;
        top: 50px;
        width: 100%;
      }
    }
  }

  #instructions {
    text-align: center;
    margin-bottom: 1em;
  }

  #output > textarea {
    width: 100%;
    height: 202px;
    border: 2px solid #9192a7;
    border-radius: 0.5rem;
    padding: 10px;
    resize: vertical;
  }
}
