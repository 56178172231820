#blacklist-box {
  display: none;
  margin-bottom: 1em;

  #blacklist-list {
    a {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: bottom;
    }

    a.blacklisted-inactive {
      text-decoration: line-through;
    }
  }

  &.sidebar-blacklist ul li {
    a {
      max-width: 75%;
    }
  }

  &.inline-blacklist {
    margin-bottom: 1em;

    #blacklist-list {
      display: inline;

      li {
        display: inline;
        margin-right: 1em;

        a {
          max-width: 25%;
        }
      }
    }
  }
}

.post-preview.blacklisted-active, .image-container.blacklisted-active, #c-comments .post.blacklisted-active {
  display: none !important;
}
