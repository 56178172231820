section#ai-metadata-section table th {
  text-align: right;
}

div.custom-metadata-field,
div.ui-dialog form.simple_form div.custom-metadata-field {
  display: flex;
  margin-bottom: 0.5em;

  input.custom-metadata-field-name {
    width: 160px;
    margin-right: 0.5em;
  }

  svg {
    color: red;
    height: 24px;
    padding-left: 0.5em;
    cursor: pointer;
  }
}

#c-ai-metadata, #c-ai-metadata-versions {
  #a-index form.simple_form {
    div.search_parameter {
      label {
        font-weight: normal;
      }
    }

    input[type="submit"] {
      margin-top: 0.5em;
    }

    .parameter-search-help {
      margin-left: 0.25em;
    }
  }
}

table.ai-metadata-table {
  th {
    width: 160px;
    word-wrap: anywhere;
  }

  td {
    word-wrap: anywhere;
  }
}
