.tag-list {
  a.search-tag {
    overflow-wrap: break-word;
  }

  &.inline-tag-list {
    display: inline;

    a {
      margin-right: 0.5em;
    }
  }

  ul {
    margin-bottom: 1em;
  }
}
