:root {
  --text-xxs: 0.5625rem; // 9px
  --text-xs: 0.6875rem;  // 11px
  --text-sm: 0.75rem;    // 12px
  --text-md: 0.875rem;   // 14px
  --text-lg: 1rem;       // 16px
  --text-xl: 1.3125rem;  // 21px
  --text-xxl: 1.75rem;   // 28px
  --emoji-font: "Twemoji";
  --header-font: "Tahoma", "Verdana", "Helvetica", var(--emoji-font), sans-serif;
  --body-font: "Verdana", "Helvetica", var(--emoji-font), sans-serif;
  --monospace-font: 0.875rem monospace; // 14px
  --arial-font: "Arial", "Helvetica", sans-serif;
}

@mixin active-link {
  color: var(--link-color);

  &:hover, &:focus {
    background-color: var(--subnav-menu-background-color);
    outline: none;
  }
}

@mixin inactive-link {
  color: var(--muted-text-color);

  &:hover {
    color: var(--link-hover-color);
    background-color: var(--subnav-menu-background-color);
  }
}
