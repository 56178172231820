/* These are fonts intended for use in notes. */

/* https://fontlibrary.org/en/font/comic-relief */
@font-face {
  font-family: "Comic Sans MS";
  font-weight: normal;
  font-display: swap;
  src:
    local("Comic Relief"),
    url("~/public/fonts/ComicRelief-Regular.woff") format("woff");
}

@font-face {
  font-family: "Comic Sans MS";
  font-weight: bold;
  font-display: swap;
  src:
    local("Comic Relief"),
    url("~/public/fonts/ComicRelief-Bold.woff") format("woff");
}

@font-face {
  font-family: "Comic";
  font-weight: normal;
  font-display: swap;
  src:
    local("Comic Relief"),
    url("~/public/fonts/ComicRelief-Regular.woff") format("woff");
}

@font-face {
  font-family: "Comic";
  font-weight: bold;
  font-display: swap;
  src:
    local("Comic Relief"),
    url("~/public/fonts/ComicRelief-Bold.woff") format("woff");
}

/* https://fonts.google.com/specimen/IBM+Plex+Mono */
@font-face {
  font-family: "Mono";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
    local("IBM Plex Mono"),
    url("@fontsource/ibm-plex-mono/files/ibm-plex-mono-latin-400-normal.woff2") format("woff2"),
    url("@fontsource/ibm-plex-mono/files/ibm-plex-mono-latin-400-normal.woff") format("woff");
}

@font-face {
  font-family: "Mono";
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src:
    local("IBM Plex Mono"),
    url("@fontsource/ibm-plex-mono/files/ibm-plex-mono-latin-400-italic.woff2") format("woff2"),
    url("@fontsource/ibm-plex-mono/files/ibm-plex-mono-latin-400-italic.woff") format("woff");
}

@font-face {
  font-family: "Mono";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src:
    local("IBM Plex Mono"),
    url("@fontsource/ibm-plex-mono/files/ibm-plex-mono-latin-700-normal.woff2") format("woff2"),
    url("@fontsource/ibm-plex-mono/files/ibm-plex-mono-latin-700-normal.woff") format("woff");
}

@font-face {
  font-family: "Mono";
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src:
    local("IBM Plex Mono"),
    url("@fontsource/ibm-plex-mono/files/ibm-plex-mono-latin-700-italic.woff2") format("woff2"),
    url("@fontsource/ibm-plex-mono/files/ibm-plex-mono-latin-700-italic.woff") format("woff");
}

/* https://fonts.google.com/specimen/Rokkitt */
@font-face {
  font-family: "Rockwell";
  font-weight: 400;
  font-display: swap;
  src:
    url("@fontsource/rokkitt/files/rokkitt-latin-400-normal.woff2") format("woff2"),
    url("@fontsource/rokkitt/files/rokkitt-latin-400-normal.woff") format("woff");
}

@font-face {
  font-family: "Rockwell";
  font-weight: 700;
  font-display: swap;
  src:
    url("@fontsource/rokkitt/files/rokkitt-latin-700-normal.woff2") format("woff2"),
    url("@fontsource/rokkitt/files/rokkitt-latin-700-normal.woff") format("woff");
}

@font-face {
  font-family: "Slab Serif";
  font-weight: 400;
  font-display: swap;
  src:
    url("@fontsource/rokkitt/files/rokkitt-latin-400-normal.woff2") format("woff2"),
    url("@fontsource/rokkitt/files/rokkitt-latin-400-normal.woff") format("woff");
}

@font-face {
  font-family: "Slab Serif";
  font-weight: 700;
  font-display: swap;
  src:
    url("@fontsource/rokkitt/files/rokkitt-latin-700-normal.woff2") format("woff2"),
    url("@fontsource/rokkitt/files/rokkitt-latin-700-normal.woff") format("woff");
}

/* https://fonts.google.com/specimen/Anton */
@font-face {
  font-family: "Impact";
  font-display: swap;
  src:
    local("Anton"),
    url("@fontsource/anton/files/anton-latin-400-normal.woff2") format("woff2"),
    url("@fontsource/anton/files/anton-latin-400-normal.woff") format("woff");
}

@font-face {
  font-family: "Slab Sans";
  font-display: swap;
  src:
    local("Anton"),
    url("@fontsource/anton/files/anton-latin-400-normal.woff2") format("woff2"),
    url("@fontsource/anton/files/anton-latin-400-normal.woff") format("woff");
}

/* https://fonts.google.com/specimen/Archivo+Narrow */
@font-face {
  font-family: "Arial Narrow";
  font-weight: 400;
  font-display: swap;
  src:
    local("Archivo Narrow"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-400-normal.woff2") format("woff2"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-400-normal.woff") format("woff");
}

@font-face {
  font-family: "Arial Narrow";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src:
    local("Archivo Narrow"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-400-italic.woff2") format("woff2"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-400-italic.woff") format("woff");
}

@font-face {
  font-family: "Arial Narrow";
  font-weight: 700;
  font-display: swap;
  src:
    local("Archivo Narrow"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-700-normal.woff2") format("woff2"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-700-normal.woff") format("woff");
}

@font-face {
  font-family: "Arial Narrow";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src:
    local("Archivo Narrow"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-700-italic.woff2") format("woff2"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-700-italic.woff") format("woff");
}

@font-face {
  font-family: "Narrow";
  font-weight: 400;
  font-display: swap;
  src:
    local("Archivo Narrow"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-400-normal.woff2") format("woff2"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-400-normal.woff") format("woff");
}

@font-face {
  font-family: "Narrow";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src:
    local("Archivo Narrow"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-400-italic.woff2") format("woff2"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-400-italic.woff") format("woff");
}

@font-face {
  font-family: "Narrow";
  font-weight: 700;
  font-display: swap;
  src:
    local("Archivo Narrow"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-700-normal.woff2") format("woff2"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-700-normal.woff") format("woff");
}

@font-face {
  font-family: "Narrow";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src:
    local("Archivo Narrow"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-700-italic.woff2") format("woff2"),
    url("@fontsource/archivo-narrow/files/archivo-narrow-latin-700-italic.woff") format("woff");
}

/* https://fonts.google.com/specimen/Lora */
@font-face {
  font-family: "Formal Serif";
  font-display: swap;
  src:
    local("Lora"),
    url("@fontsource/lora/files/lora-latin-400-normal.woff2") format("woff2"),
    url("@fontsource/lora/files/lora-latin-400-normal.woff") format("woff");
}

@font-face {
  font-family: "Formal Serif";
  font-weight: bold;
  font-display: swap;
  src:
    local("Lora"),
    url("@fontsource/lora/files/lora-latin-700-normal.woff2") format("woff2"),
    url("@fontsource/lora/files/lora-latin-700-normal.woff") format("woff");
}

@font-face {
  font-family: "Formal Serif";
  font-style: italic;
  font-display: swap;
  src:
    local("Lora"),
    url("@fontsource/lora/files/lora-latin-400-italic.woff2") format("woff2"),
    url("@fontsource/lora/files/lora-latin-400-italic.woff") format("woff");
}

@font-face {
  font-family: "Formal Serif";
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src:
    local("Lora"),
    url("@fontsource/lora/files/lora-latin-700-italic.woff2") format("woff2"),
    url("@fontsource/lora/files/lora-latin-700-italic.woff") format("woff");
}

/* https://fonts.google.com/specimen/Lora */
@font-face {
  font-family: "Print";
  font-display: swap;
  src:
    local("Kalam"),
    url("~/public/fonts/Kalam-Regular.woff") format("woff");
}

@font-face {
  font-family: "Print";
  font-weight: bold;
  font-display: swap;
  src:
    local("Kalam"),
    url("~/public/fonts/Kalam-Bold.woff") format("woff");
}

/* https://fonts.google.com/specimen/Indie+Flower */
@font-face {
  font-family: "Hand";
  font-display: swap;
  src:
    local("Indie Flower"),
    url("@fontsource/indie-flower/files/indie-flower-latin-400-normal.woff2") format("woff2"),
    url("@fontsource/indie-flower/files/indie-flower-latin-400-normal.woff") format("woff");
}

/* https://fonts.google.com/specimen/Petit+Formal+Script */
@font-face {
  font-family: "Formal Cursive";
  font-display: swap;
  src:
    local("Petit Formal Script"),
    url("@fontsource/petit-formal-script/files/petit-formal-script-latin-400-normal.woff2") format("woff2"),
    url("@fontsource/petit-formal-script/files/petit-formal-script-latin-400-normal.woff") format("woff");
}

/* https://fonts.google.com/specimen/UnifrakturMaguntia */
@font-face {
  font-family: "Blackletter";
  font-display: swap;
  src:
    local("UnifrakturMaguntia"),
    url("@fontsource/unifrakturmaguntia/files/unifrakturmaguntia-latin-400-normal.woff2") format("woff2"),
    url("@fontsource/unifrakturmaguntia/files/unifrakturmaguntia-latin-400-normal.woff") format("woff");
}

/* https://www.dafont.com/anarchy2.font */
@font-face {
  font-family: "Scary";
  font-display: swap;
  src:
    local("Anarchy"),
    url("~/public/fonts/Anarchy.ttf") format("truetype");
}

/* https://www.1001freefonts.com/gisele-script.font */
@font-face {
  font-family: "Childlike";
  font-display: swap;
  src:
    local("Gisele Script"),
    url("~/public/fonts/gisele.ttf") format("truetype");
}

/*
 * https://github.com/jdecked/twemoji
 * https://github.com/mozilla/twemoji-colr
 * https://github.com/mrdrogdrog/twemoji-color-font
 */
@font-face {
  font-family: "Twemoji";
  font-display: swap;

  /*
   * Make it so the font is only loaded when emoji characters are present. This range includes some non-emoji, but
   * that's okay because it just means the font may be loaded for non-emoji, but it won't actually be used. An exact
   * list would be enormous.
   *
   * https://en.wikipedia.org/wiki/List_of_emojis
   */
  unicode-range: U+00A9, U+00AE, U+203C-2B55, U+3030, U+303D, U+3297, U+3299, U+1F000-1FFFF;

  src:
    /* url("https://cdn.jsdelivr.net/npm/twemoji-colr-font@15.0.3/twemoji.woff2") format("woff2"); */
    url("twemoji-colr-font/twemoji.woff2") format("woff2");
}
